define(['app'], (app) => {

  const displaySubscriptionPreferences = () => {
    const component = {};

    const _config = {
      attributes: {
        dynamicNewsletterSubscription: 'data-dynamic-newsletter-subscription'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.emailInput = document.querySelector('[type=email][name=customerEmail]');
      component.optInRadioButton = document.querySelector('input[name=OptInReceiveNewsLetterRadio][value=true]');
      component.dynamicNewsletterSubscriptionEnabled = component.element.getAttribute(component.config.attributes.dynamicNewsletterSubscription) === 'true'
      component.subscriptionPref = document.querySelector('.createAccount_subscriptionPreferences-hidden');
      component.useCreateAccount = component.subscriptionPref;
      if (!component.useCreateAccount) {
        component.subscriptionPref = document.querySelector('.accountSignUp_subscriptionPreferences-hidden');
      }
      component.bind();
      if (component.emailInput.value) {
        // in the case that an email address already exists, perform a dynamic opt-in check immediately
        component.subscriptionDisplayCheck();
      }
      return component;
    };

    const _bind = () => {
      component.emailInput.addEventListener('blur', component.subscriptionDisplayCheck);
    };

    const _subscriptionDisplayCheck = (event) => {
      let email;
      if (event) {
        email = event.currentTarget.value;
        if (!email) {
          // email input has gone back to empty, so should hide it anyway
          component.setDisplayPreference('false');
          return;
        }
      } else if (component.emailInput.value) {
        email = component.emailInput.value;
      } else {
        component.setDisplayPreferenceError();
        return;
      }

      app.ajax.post({
        url: '/displaySubscriptionPref.account',
        send: {
          email: email
        },
        requestHeader: {
          header: 'Content-Type',
          value: 'application/json'
        },
        success: component.setDisplayPreference,
        error: component.setDisplayPreferenceError
      });
    };

    const _setDisplayPreference = (data) => {
      component.subscriptionPref.classList.toggle(component.useCreateAccount
        ? 'createAccount_subscriptionPreferences-hidden' : 'accountSignUp_subscriptionPreferences-hidden',  data === 'false');

      // Set the value of the marketing preferences to true if dynamic subscription newsletter is enabled
      // and account service returns that the preferences should be hidden.
      // Otherwise remove the default value.
      if (data === 'false' && component.dynamicNewsletterSubscriptionEnabled && component.optInRadioButton) {
        component.optInRadioButton.checked = true;
      } else if (component.optInRadioButton && component.optInRadioButton.checked) {
        component.optInRadioButton.checked = false;
      }
    };

    const _setDisplayPreferenceError = () => {
      component.subscriptionPref.classList.remove(component.useCreateAccount
        ? 'createAccount_subscriptionPreferences-hidden' : 'accountSignUp_subscriptionPreferences-hidden');
      component.optInRadioButton && component.optInRadioButton.checked && (component.optInRadioButton.checked = false);
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.subscriptionDisplayCheck = _subscriptionDisplayCheck;
    component.setDisplayPreference = _setDisplayPreference;
    component.setDisplayPreferenceError = _setDisplayPreferenceError;

    return component;
  };

  return displaySubscriptionPreferences;
});
